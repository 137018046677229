import React from 'react';
import Plx from 'react-plx';
import Logo from "../components/svg/logo.inline.svg";
import Nav from "../components/nav"
import Logos from "../components/HomeLogos"
import BeatsMusic from "../components/svg/beatsmusic.inline.svg";
import AppleMusic from "../components/svg/applemusic.inline.svg";
import B1Radio from "../components/svg/b1.inline.svg";
import Tokr from "../components/svg/tokr.inline.svg";

import zane from "../images/b1-zane.jpg";
import ebro from "../images/b1-ebro.jpg";
import julie from "../images/b1-julie.jpg";
import b1UI from "../images/b1-ui.jpg";
import b1Anchors1 from "../images/b1-anchors1.jpg";
import b1Anchors2 from "../images/b1-anchors2.jpg";
import b1Anchors3 from "../images/b1-anchors3.jpg";
import b1RTJ from "../images/b1-rtj.jpg";
import b1RTJ2 from "../images/b1-rtj2.jpg";
import b1RTJ3 from "../images/b1-rtj3.jpg";
import b1RTJposter from "../images/b1-rtjposter.jpg";
import b1RTJvid from "../videos/b1-rtj.mp4"
import b1mktg from "../images/b1-mktg.jpg";
import b1web from "../images/b1-web.jpg";
import b1social from "../images/b1-social.jpg";

import applemag from "../images/apple-PLmag.jpg";
import appleooh from "../images/apple-PLooh.jpg";
import applemag2 from "../images/apple-PLmag2.jpg";
import applesubway from "../images/apple-PLsubway.jpg";

import beatsweb from "../images/beats-web.jpg";
import beatsposter from "../images/beats-poster.jpg";
import beatsvid from "../videos/beats-video.mp4"
import beatsartist from "../images/beats-artist.jpg";
import beatsemail from "../images/beats-email.jpg";
import beatsad from "../images/beats-ad.jpg";

import Footer from "../components/footer"

/* LOGO */
const PlxLogoData = [
  {
    start: 800,
    end: 1800,
    easing: "ease",
    properties: [
      {
        startValue: 0,
        endValue: -1000,
        property: "translateY"
      },
    ]
  },
];
class PlxLogo extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxLogoData } animateWhenNotInViewport={ true }>
        <nav id="nav" className="nav">
          <div id="MElogo" className="logo"><Logo /></div>
        </nav>
      </Plx>
    );
  }
}

//  SECTION 1 : HOME TEXT A
const PlxHomeTextAdata = [
  {
    start: 600,
    end: 601,
    properties: [
      {
        startValue: 1,
        endValue: -10000,
        property: "translateY"
      },
    ]
  }
];
class PlxHomeTextA extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxHomeTextAdata } animateWhenNotInViewport={ true }>
        <div className="homeText One">
          <PlxH1A />
          <PlxH1B />
          <PlxH4A />
        </div>
      </Plx>
    );
  }
}

const PlxH1Adata = [
  {
    start: 100,
    end: 200,
    properties: [
      {
        startValue: 1,
        endValue: 10,
        property: "translateX"
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity"
      }
    ]
  },
];
class PlxH1A extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxH1Adata } animateWhenNotInViewport={ true }>
        <h1 className="PlxH1A">We are</h1>
      </Plx>
    );
  }
}
const PlxH1Bdata = [
  {
    start: 200,
    end: 300,
    properties: [
      {
        startValue: 1,
        endValue: 10,
        property: "translateX"
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity"
      }
    ]
  },
];
class PlxH1B extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxH1Bdata } animateWhenNotInViewport={ true }>
        <h1 className="lineTwo PlxH1B">Madequal.</h1>
      </Plx>
    );
  }
}
const PlxH4Adata = [
  {
    start: 300,
    end: 400,
    properties: [
      {
        startValue: 1,
        endValue: 10,
        property: "translateX"
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity"
      }
    ]
  }
];
class PlxH4A extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxH4Adata } animateWhenNotInViewport={ true }>
        <h4>Nashville-based creative & digital solutions – strategy to execution.<br />
          <a className="animated-arrow" href='/contact'>
            <span className="the-arrow -left">
              <span className="shaft"></span>
            </span>
            <span className="main">
              <span className="text">
                Let's Collaborate
              </span>
              <span className="the-arrow -right">
                <span className="shaft"></span>
              </span>
            </span>
          </a>
        </h4>
      </Plx>
    );
  }
}


//  SECTION 1 : HOME TEXT B
const PlxHomeTextBdata = [
  {
    start: 0,
    end: 1,
    properties: [
      {
        startValue: -10000,
        endValue: -10001,
        property: "translateY"
      }
    ]
  },
  {
    start: 300,
    end: 301,
    properties: [
      {
        startValue: -10000,
        endValue: 0,
        property: "translateY"
      }
    ]
  },
  {
    start: 800,
    end: 1800,
    properties: [
      {
        startValue: 0,
        endValue: -1000,
        property: "translateY"
      }
    ]
  }
];
class PlxHomeTextB extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxHomeTextBdata } animateWhenNotInViewport={ true }>
        <div className="homeText Two">
          <PlxH1C />
          <PlxH1D />
          <PlxH4B />
        </div>
      </Plx>
    );
  }
}

const PlxH1Cdata = [
  {
    start: 300,
    end: 400,
    properties: [
      {
        startValue: -10,
        endValue: 1,
        property: "translateX"
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity"
      }
    ]
  },
];
class PlxH1C extends React.Component {
  render() {
    return (
      <Plx className="invisible" parallaxData={ PlxH1Cdata } animateWhenNotInViewport={ true }>
        <h1 className="PlxH1C">This is</h1>
      </Plx>
    );
  }
}
const PlxH1Ddata = [
  {
    start: 300,
    end: 400,
    properties: [
      {
        startValue: -10,
        endValue: 1,
        property: "translateX"
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity"
      }
    ]
  },
];
class PlxH1D extends React.Component {
  render() {
    return (
      <Plx className="invisible" parallaxData={ PlxH1Ddata } animateWhenNotInViewport={ true }>
        <h1 className="lineTwo PlxH1D">Me.</h1>
      </Plx>
    );
  }
}
const PlxH4Bdata = [
  {
    start: 300,
    end: 400,
    properties: [
      {
        startValue: -10,
        endValue: 1,
        property: "translateX"
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity"
      }
    ]
  }
];
class PlxH4B extends React.Component {
  render() {
    return (
      <Plx className="invisible" parallaxData={ PlxH4Bdata } animateWhenNotInViewport={ true }>
        <h4>I'm <b><i>Brian</i></b>, a creative leader & digital innovator.<br />
          <a className="animated-arrow" href='/about'>
            <span className="the-arrow -left">
              <span className="shaft"></span>
            </span>
            <span className="main">
              <span className="text">
                Learn More
              </span>
              <span className="the-arrow -right">
                <span className="shaft"></span>
              </span>
            </span>
          </a>
        </h4>
      </Plx>
    );
  }
}

//  SECTION 2 : HOME TEXT C
const PlxHomeTextCdata = [
  {
    start: 0,
    end: 1,
    properties: [
      {
        startValue: 1000,
        endValue: 1000,
        property: "translateY"
      },
    ]
  },
  {
    start: 600,
    end: 1600,
    properties: [
      {
        startValue: 1000,
        endValue: 0,
        property: "translateY"
      },
    ]
  },
  {
    start: 3300,
    end: 4300,
    properties: [
      {
        startValue: 0,
        endValue: -1000,
        property: "translateY"
      }
    ]
  }
];
class PlxHomeTextC extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxHomeTextCdata } animateWhenNotInViewport={ true }>
        <div className="homeText One">
          <PlxH1E />
          <PlxH1F />
          <PlxH4C />
        </div>
      </Plx>
    );
  }
}

const PlxH1Edata = [
  {
    start: 2200,
    end: 2300,
    properties: [
      {
        startValue: 1,
        endValue: 10,
        property: "translateX"
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity"
      }
    ]
  },
];
class PlxH1E extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxH1Edata } animateWhenNotInViewport={ true }>
        <h1>Building Brands</h1>
      </Plx>
    );
  }
}
const PlxH1Fdata = [
  {
    start: 2300,
    end: 2400,
    properties: [
      {
        startValue: 1,
        endValue: 10,
        property: "translateX"
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity"
      }
    ]
  },
];
class PlxH1F extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxH1Fdata } animateWhenNotInViewport={ true }>
        <h1 className="lineTwo">That <span>Inspire.</span></h1>
      </Plx>
    );
  }
}
const PlxH4Cdata = [
  {
    start: 2400,
    end: 2500,
    properties: [
      {
        startValue: 1,
        endValue: 10,
        property: "translateX"
      },
      {
        startValue: 1,
        endValue: 0,
        property: "opacity"
      }
    ]
  }
];
class PlxH4C extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxH4Cdata } animateWhenNotInViewport={ true }>
        <h4>I believe in <b><i>giving more than you take,</i></b> and innovating to create a better future.<br />
          <a className="animated-arrow" href='/work'>
            <span className="the-arrow -left">
              <span className="shaft"></span>
            </span>
            <span className="main">
              <span className="text">
                Check Out My Work
              </span>
              <span className="the-arrow -right">
                <span className="shaft"></span>
              </span>
            </span>
          </a>
        </h4>
      </Plx>
    );
  }
}


//  SECTION 2 : HOME TEXT D
const PlxHomeTextDdata = [
  {
    start: 0,
    end: 1,
    properties: [
      {
        startValue: -10000,
        endValue: -10001,
        property: "translateY"
      }
    ]
  },
  {
    start: 2300,
    end: 2301,
    properties: [
      {
        startValue: -10000,
        endValue: 0,
        property: "translateY"
      }
    ]
  },
  {
    start: 3300,
    end: 4300,
    properties: [
      {
        startValue: 0,
        endValue: -1000,
        property: "translateY"
      }
    ]
  }
];
class PlxHomeTextD extends React.Component {
  render() {
    return (
      <Plx parallaxData={ PlxHomeTextDdata } animateWhenNotInViewport={ true }>
        <div className="homeText Two">
          <PlxH1G />
          <PlxH1H />
          <PlxH4D />
        </div>
      </Plx>
    );
  }
}

const PlxH1Gdata = [
  {
    start: 2300,
    end: 2400,
    properties: [
      {
        startValue: -10,
        endValue: 1,
        property: "translateX"
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity"
      }
    ]
  },
];
class PlxH1G extends React.Component {
  render() {
    return (
      <Plx className="invisible" parallaxData={ PlxH1Gdata } animateWhenNotInViewport={ true }>
        <h1>Creativity</h1>
      </Plx>
    );
  }
}
const PlxH1Hdata = [
  {
    start: 2400,
    end: 2500,
    properties: [
      {
        startValue: -10,
        endValue: 1,
        property: "translateX"
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity"
      }
    ]
  },
];
class PlxH1H extends React.Component {
  render() {
    return (
      <Plx className="invisible" parallaxData={ PlxH1Hdata } animateWhenNotInViewport={ true }>
        <h1 className="lineTwo">With <span>Purpose.</span></h1>
      </Plx>
    );
  }
}
const PlxH4Ddata = [
  {
    start: 2500,
    end: 2600,
    properties: [
      {
        startValue: -10,
        endValue: 1,
        property: "translateX"
      },
      {
        startValue: 0,
        endValue: 1,
        property: "opacity"
      }
    ]
  }
];
class PlxH4D extends React.Component {
  render() {
    return (
      <Plx className="invisible" parallaxData={ PlxH4Ddata } animateWhenNotInViewport={ true }>
        <h4>Together we can <b><i>change the world</i></b> with powerful ideas.<br />
          <a className="animated-arrow" href='/contact'>
            <span className="the-arrow -left">
              <span className="shaft"></span>
            </span>
            <span className="main">
              <span className="text">
                Let's Start Some Shit
              </span>
              <span className="the-arrow -right">
                <span className="shaft"></span>
              </span>
            </span>
          </a>
        </h4>
      </Plx>
    );
  }
}


export default () => (
  <div>
    <Nav />
    <div className="PLXwrapper meLogo">
      <PlxLogo />
    </div>
    <div className="PLXwrapper">
      <PlxHomeTextA />
      <PlxHomeTextB />
      <div className="red">
        <PlxHomeTextC />
        <PlxHomeTextD />
      </div>
    </div>
    <div id="section1">
      <div className="section"></div>
    </div>
    <div id="section2" className="red">
      <div className="section red"></div>
    </div>
    <div id="section3" className="section">
      <div className="content">
        <div className="pad">
          <h1>Featured</h1>
          <h1 className="lineTwo">Clients.</h1>
          <h4>From startups to worldwide, I’ve been on the ride.</h4>
        </div>
      </div>
      <div id="workB1" className="work">
        <B1Radio />
        <h4>Worldwide. Always On.</h4>
        <div className="workOverview">
          <div className="half left">
            <ul>
              <li>Brand</li>
              <li>Platforms</li>
              <li>Design Systems</li>
              <li>Campaigns</li>
              <li>Social</li>
            </ul>
          </div>
          <div className="half right">
            <div className="pad">
              <p>
                The flagship station for Apple Music – Beats 1 Radio drives the heart and soul of music exploration. 24/7 and always on, Beats 1 is a constant flow of artist-driven and curated content. As a small and nimble team, we designed web and native platforms to house this treasure trove of stored content – and also worked with the artists to develop brands for each show, create unique art and marketing content for dozens of shows and thousands of recorded episodes.
                {/*<br /><a href="/b1">View Project</a>*/}
              </p>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="full"><img src={b1UI} alt="B1 UI" /></div>
        <div className="half textLeft">
          <div className="pad">
            <h3><span>Platforms</span></h3>
            <h5>Web & App</h5>
            <p>
              Within Apple Music, we were key stakeholders and designers in driving the needs and expectations for Beats 1 Radio including live streaming and on demand functionality. This included anchor & show pages, schedules, and the browsing experience of thousands of episodes.
              <br /><br />We also imagined, pitched and created a web presence for Beats 1 Radio as an arm of the larger Apple ecosystem. Using Apple APIs we tied into the live schedule, and married this stream of content with incredible featured content to create a dynamic web experience.
            </p>
          </div>
        </div>
        <div className="full"><img src={b1web} alt="B1 Web" /></div>
        <div className="half textRight">
          <div className="pad">
            <h3>Design <span>Systems</span></h3>
            <h5>Over 50 Shows.<br />1000's of Episodes.</h5>
            <p>
              Beats 1 Radio truly was worldwide, and always on. We were faced with the challenge to brand each show, and create custom episode art for nearly every episode. The anchors – Zane, Ebro, and Julie – each hosted a daily show, which in itself created a demand for nearly a thousand pieces of episode art, which could only be manged through scalable and templated systems that were recognizable for each show.
            </p>
          </div>
        </div>
        <div className="third square left"><img src={zane} alt="zane" /></div>
        <div className="third square mobileHide left"><img src={ebro} alt="ebro" /></div>
        <div className="third square mobileHide last left"><img src={julie} alt="julie" /></div>
        <div className="clear"></div>
        <div className="third square left"><img src={b1Anchors1} alt="zane" /></div>
        <div className="third square mobileHide left"><img src={b1Anchors2} alt="ebro" /></div>
        <div className="third square mobileHide last left"><img src={b1Anchors3} alt="julie" /></div>
        <div className="clear"></div>
        <div className="half square text left">
          <div className="pad center">
            <h3><span>Artist Driven</span> Radio</h3>
            <h5>Over 50 Shows.<br />1000's of Episodes.</h5>
            <p>
            Artist-driven radio is the beating heart of music exploration in Beats 1 Radio. We worked with artists to brand each show, and create custom art and social promotions for nearly every episode. This massive undertaking was supported through an external agency which allowed us to turn around art and animations over night. Key artists we worked with included Run the Jewels (show below), Elton John, Q-Tip, Dr Dre, St. Vincent, and many more.
            </p>
          </div>
        </div>
        <div className="half square right b1Artist"><img src={b1RTJ} alt="B1 RTJ" /></div>
        <div className="clear"></div>
        <div className="half left"><img src={b1RTJ2} alt="B1 RTJ" /></div>
        <div className="half right"><img src={b1RTJ3} alt="B1 RTJ" /></div>
        <div className="clear"></div>
        <div className="half textRight">
          <div className="pad">
            <h3>360 <span>Marketing</span></h3>
            <h5>Over 50 Shows.<br />1000's of Episodes.</h5>
            <p>
              As the only internal team dedicated to all of Beats 1 Radio, I led a team of creatives to not only support the marketing efforts of the station and it's social channels as a whole – we also drove the marketing design efforts for our top-billed artist shows. Our work supported artists-owned social channels, created new digital channels for shows, and executed OOH in strategic local markets.
            </p>
          </div>
        </div>
        <video controls poster={b1RTJposter} id="videoPlayer">
          <source src={b1RTJvid} type="video/mp4" />
        </video>
        <div className="full"><img src={b1social} alt="B1 Social" /></div>
        <div className="full"><img src={b1mktg} alt="B1 Marketing" /></div>
      </div>
      <div id="workApple" className="work">
        <AppleMusic />
        <h4>60 Million Songs In Your Pocket</h4>
        <div className="workOverview">
          <div className="half left">
            <ul>
              <li>Website</li>
              <li>Campaigns</li>
              <li>Digital</li>
            </ul>
          </div>
          <div className="half right">
            <div className="pad">
              <p>
                My team was part of the acquisition of Beats by Dre to Apple. A large part of the acquisition was to bring in the team responsible for building Beats Music to help transform iTunes into the streaming platform Apple Music is today. It takes a teams of monumental proportions to create a project like this, and I were honored to be part of the process from the ground up.
                {/*<br /><a href="/apple">View Project</a>*/}
              </p>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="full"><img src={appleooh} alt="Apple OOH" /></div>
        <div className="half square text left">
          <div className="pad center">
            <h3>Apple <span>Playlists</span></h3>
            <h5>Apple's Original Content.</h5>
            <p>
              Before B1, Apple escalated it's competition with Spotify by focusing on original content in the form of playlists and radio stations. We art-directed dozens of playlists by genre, activity, and lifestyle – and augmented marketing efforts by promoting the content via digital, social, print, and out of home campaigns.
            </p>
          </div>
        </div>
        <div className="half square right"><img src={applemag} alt="Apple Mag" /></div>
        <div className="clear"></div>
        <div className="half square left"><img src={applemag2} alt="Apple Mag2" /></div>
        <div className="clear"></div>
        <div className="full"><img src={applesubway} alt="Apple Subway" /></div>
      </div>
      <div id="workBeats" className="work">
        <BeatsMusic />
        <h4>A Music Service <span>With Heart</span></h4>
        <div className="workOverview">
          <div className="half left">
            <ul>
              <li>Web</li>
              <li>UI / UX</li>
              <li>Campaigns</li>
            </ul>
          </div>
          <div className="half right">
            <div className="pad">
              <p>
                Beats Music was at the forefront of personalized music exploration in streaming. Through analyzing your music tastes and genre preferences, Beats Music would use data to create recommendations and playlists that were just for you. As Creative Director of Marketing, my team ws resonsible for the website design, content & development, partnerships, campaigns on paid, owned, and earned channels, and much of the visual language that represented the brand as a whole.
                {/*<br /><a href="/beats">View Project</a>*/}
              </p>
            </div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="full"><img src={beatsweb} alt="Beats Web" /></div>
        <video controls poster={beatsposter} id="videoPlayer">
          <source src={beatsvid} type="video/mp4" />
        </video>
        <div className="half square text left">
          <div className="pad center">
            <h3><span>Email</span> Marketing</h3>
            <h5>Dynamic music marketing.</h5>
            <p>
              We tied into Beats users' profiles to send curated music recommendations – along with the ability to like, hate or save – directly in their inbox.
            </p>
          </div>
        </div>
        <div className="half square right"><img src={beatsemail} alt="Beats Email" /></div>
        <div className="clear"></div>
        <div className="full"><img src={beatsad} alt="Beats Ad" /></div>
        <div className="half textRight">
          <div className="pad">
            <h3><span>Web</span></h3>
            <h5>Site & Artist Dashboard</h5>
            <p>
              beatsmusic.com was quite simply, a marketing tool that promoted an app – but we created it with a style and dynamic content that precluded the experience of the beautiful interface of the streaming service for our users. The artist dashboard was a love project of Trent Reznor (NIN) that began before my time at Beats Music, but quickly became a passion project as it provided artists with tools they never had access to on how to grow their business.
            </p>
          </div>
        </div>
        <div className="full"><img src={beatsartist} alt="Beats Artist Dashboard" /></div>
      </div>
      <div id="workTokr" className="work">
        <Tokr />
        <h4>Delivering a Personalized Cannabis Experience.</h4>
        <div className="half left">
          <ul>
            <li>Brand Identity</li>
            <li>Web</li>
            <li>App</li>
            <li>Marketing</li>
            <li>Digital</li>
          </ul>
        </div>
        <div className="half right">
          <div className="pad">
            <p>
              t&ouml;kr was created with the vision of educating consumers about healthy consumption of cannabis, and ensuring a safe and professional commerce experience – from discovery to delivery. In the California market, we truly were pioneers envisioning an industry that is post-prohibition. Working only with licensed, legal, and premium brands and products, we created a top notch e-commerce platform that curated cannabis based on need – better sleep, relaxation, focus, and pain relief – to professionals, parents, and connoiseurs of high quality experiences.
              {/*<br /><a href="/b1">View Project</a>*/}
            </p>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
    <div className="PLXwrapper homeLogos">
      <Logos />
    </div>
    <Footer />
  </div>
)
