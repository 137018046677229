import React from "react"
import { Link } from 'gatsby';

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isToggleOn: true};
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }
  render() {
    return (
      <div className={this.state.isToggleOn ? 'toggle off' : 'toggle on'}>
        <div className="openClose" role="link" tabIndex={0} onClick={this.handleClick} onKeyDown={this.handleClick}>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div id="navBase"></div>
        <div className="navList">
          <ListLink to="/about">About Me.</ListLink><br />
          <ListLink to="/work">Work</ListLink><br />
          <ListLink to="/process">Process</ListLink><br />
          <ListLink to="/contact">Contact Me.</ListLink>
        </div>
      </div>
    );
  }
}

export default () => (
  <div>
    <Toggle />
  </div>
)
